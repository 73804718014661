//import { Controller } from "@hotwired/stimulus"
import { Modal } from "tailwindcss-stimulus-components"

// Connects to data-controller="extended-modal"
export default class ExtendedModal extends Modal {
  static targets = ["form", "errors", "container"]
  connect() {
    super.connect()
  }
  open(e) {
    super.open(e)
  }
  handleSuccess({ detail: { success } }) {
    if (success) {
      super.close()
      this.clearErrors()
      this.formTarget.reset()
    }
  }

  clearErrors() {
    if (this.hasErrorsTarget) {
      this.errorsTarget.remove()
    }
  }
}
